@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');

body {
  margin: 0;
  font-family: 'Rubik';
  overflow-x: hidden;
  background-color: rgb(250, 250, 250);
}

.text-weight-400 {
  font-weight: 400;
}

.text-weight-500 {
  font-weight: 500;
}

.text-weight-600 {
  font-weight: 600;
}

.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.text-black-06 {
  color: rgba(0, 0, 0, 0.6);
}

.text-gray-6 {
  color: rgba(255, 255, 255, 0.6);
}

.text-green-main {
  color: rgba(225, 241, 102, 1);
}

.text-blue-gray {
  color: rgba(26, 62, 104, 0.48);
}

.text-04 {
  color: rgba(0, 0, 0, 0.4);
}

.text-blue {
  color: rgba(2, 112, 252, 1);
}

.text-10 {
  font-size: 10px;
}

.text-12 {
  font-size: 12px;
}

.text-13 {
  font-size: 13px;
}

.text-14 {
  font-size: 14px;
}

.text-15 {
  font-size: 15px;
}

.text-16 {
  font-size: 16px;
}

.text-20 {
  font-size: 20px;
}

.text-22 {
  font-size: 22px;
}

.text-24 {
  font-size: 24px;
}

.text-28 {
  font-size: 28px;
}

.text-40 {
  font-size: 40px;
}

.MuiCheckbox-colorSecondary {
  color: black;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: rgba(225, 241, 102, 1);
}

.color-gray-1 {
  color: 'rgba(229, 229, 229, 1)';
}

.gray-34-1 {
  color: rgba(34, 34, 34, 1);
}

.gray-153-1 {
  color: rgba(153, 153, 153, 1);
}

.rowBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
